.smallScreenShow {
  display: none;
}

@media (max-width: 915px) {
  .smallScreenHide {
    display: none;
  }

  .smallScreenShow {
    display: block;
  }
}